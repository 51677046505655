export const areaCenterPosition: { [key: string]: string } = {
  ginza: '35.67122,139.76505',
  yurakucho: '35.6750733,139.7624948',
  hibiya: '35.674569,139.7594108',
  omotesando: '35.6652478,139.7104378',
}
export const GINZA_CENTER = { lat: 35.67122, lng: 139.76505 }

export const COMMON_METADATA = [
  { name: 'chrome', content: 'nointentdetection' },
  { name: 'format-detection', content: 'telephone=no,email=no,address=no' },
  { name: 'theme-color', content: '#179AAE' },
]
